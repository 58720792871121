import { css } from '@emotion/react';

import themeCSSObj from '~/styles/theme.module.scss';
import { getColorAlpha } from '~/lib/color';
import { getErrorTextColor } from '~/lib/color';

export const formErrorBox = css({
  height: '36px',
  padding: '0 16px',
  marginBottom: '20px',
  marginTop: '24px',
  border: `1px solid ${themeCSSObj.paletteErrorMain}`,
  borderRadius: themeCSSObj.appShapeBorderRadius1,
  fontSize: themeCSSObj.typographySubtitle1FontSize,
  lineHeight: '36px',
  color: themeCSSObj.paletteErrorMain,
  background: getColorAlpha(getErrorTextColor(), 0.1),
});

export const loginFormBox = css({
  'input::placeholder': {
    color: themeCSSObj.paletteCommonWhite,
    opacity: 0.8,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: 0,
    borderBottomColor: getColorAlpha(themeCSSObj.paletteCommonWhite, 0.5),
  },
  '& .MuiOutlinedInput-input': {
    backgroundColor: 'unset !important',
    color: `${themeCSSObj.paletteCommonWhite} !important`,
  },
  '& .MuiOutlinedInput-input:-webkit-autofill': {
    textFillColor: themeCSSObj.paletteCommonWhite,
    caretColor: themeCSSObj.paletteCommonWhite,
    transition: 'background-color 50000s ease-in-out 0s',
  },
  '& .MuiOutlinedInput-root': {
    width: '100%',
    'input::placeholder': {
      color: themeCSSObj.paletteCommonWhite,
      opacity: 0.8,
    },
    backgroundColor: 'unset !important',
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderBottomColor: getColorAlpha(themeCSSObj.paletteCommonWhite, 0.8),
      },
    },
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: 'none !important',
      borderBottomColor: `${themeCSSObj.paletteCommonWhite} !important`,
    },
  },
  '& .Mui-error': {
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderBottomColor: themeCSSObj.paletteErrorMain,
      },
    },
  },
});

export const InputCSS = css({
  '& input[type="password"]::-ms-reveal': {
    display: 'none',
  },
  '& input:-webkit-autofill': {
    textFillColor: themeCSSObj.paletteCommonWhite,
    caretColor: themeCSSObj.paletteCommonWhite,
    transition: 'background-color 50000s ease-in-out 0s',
  },
});
