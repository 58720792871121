import { css } from '@emotion/react';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';

import themeCSSObj from '~/styles/theme.module.scss';

export const getOutlinedInputCSS = (width: string, height: string, isError: boolean) =>
  css({
    backgroundColor: themeCSSObj.paletteCommonWhite,
    width: width,
    '&:hover': {
      [`.${outlinedInputClasses['notchedOutline']}`]: {
        borderColor: isError ? themeCSSObj.paletteErrorMain : themeCSSObj.appPrimaryColor,
      },
    },
    [`.${outlinedInputClasses['input']}`]: {
      height: height,
      color: themeCSSObj.appFontColor1,
      fontSize: themeCSSObj.typographySubtitle1FontSize,
      boxSizing: 'border-box',
      borderRadius: themeCSSObj.appShapeBorderRadius1,
      padding: '7px 8px',
      backgroundColor: themeCSSObj.paletteCommonWhite,
    },
    [`.${outlinedInputClasses['adornedEnd']}`]: {
      padding: 0,
    },
  });

export const adornmentCSS = css({
  [`.${inputAdornmentClasses['positionEnd']}`]: {
    margin: '7px',
    fontSize: themeCSSObj.typographySubtitle1FontSize,
    color: themeCSSObj.appFontColor3,
  },
});
