import React from 'react';
import Image from 'next/image';
import { Box, Grid } from '@mui/material';

import DialogStack from '~/components/common/DialogStack';
import { LoginPage } from '~/pages-ui/login/index';
import { LOGOUT } from '~/constants/ps-events';
import { removeUserId } from '~/lib/store';
import dialog from '~/lib/dialog';
import ps from '~/lib/ps';
import backgroundImg from '~/public/images/login-background.png';
import themeCSSObj from '~/styles/theme.module.scss';
import { IMAGE_QUALITY } from '~/constants';

export default function Login(props: Record<string, any>) {
  React.useEffect(() => {
    dialog.clear();
    ps.publish(LOGOUT);
    removeUserId(); // 自动登出也需要移除 userId 信息
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: themeCSSObj.paletteCommonBlack,
      }}
    >
      <Image
        alt="LOGIN_BACKGROUND"
        src={backgroundImg.src}
        quality={IMAGE_QUALITY}
        layout="fill"
        priority={true}
        objectFit="cover"
      />
      <Grid container>
        <Grid item xs={7} />
        <Grid>
          <LoginPage {...props} />
        </Grid>
      </Grid>
      <DialogStack />
    </Box>
  );
}
