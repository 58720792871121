import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Button from '~/components/ui/Button';
import { rsaEncrypt } from '~/lib/rsa-encrypt';
import appRoute, { AppRouteKey } from '~/lib/route';
import { setUserId, setEmailSettingAlert } from '~/lib/store';
import {
  useGetRsaKeyMutation,
  useGetTokenMutation,
} from '~/__generated__/graphql/app/operations/login.graphql';
import Field, { FieldGroup } from '~/components/common/Field';
import PasswordInput from '~/components/common/PasswordInput';
import Input from '~/components/ui/Input';
import { CheckboxGroup } from '~/components/ui/Checkbox';
import Form from '~/components/common/Form';
import message from '~/lib/message';
import { setUserName } from '~/lib/store';
import { LOGIN } from '~/constants/ps-events';
import ps from '~/lib/ps';
import { getLocalLanguage, getUserName } from '~/lib/store';
import themeCSSObj from '~/styles/theme.module.scss';
import Typography from '~/components/blocks/Typography';
import dialog from '~/lib/dialog';
import { CheckboxGroupOption } from '~/components/ui/Checkbox/type';

import { formErrorBox, loginFormBox } from './lib';
import { LoginMessageType, LoginInputType, LoginFormPropsType } from './type';

const LoginMessage = (props: LoginMessageType) => {
  const { getUserInputChange, backToLogin, isOAuthLogin, showErrorStyle } = props;
  const inputRef = useRef<HTMLElement>(null);
  const { control, watch } = useFormContext();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const [name, password] = watch(['name', 'password']);
  useEffect(() => {
    getUserInputChange(name, password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, password]);

  const showNameField = useMemo(() => {
    return (getUserName() && !backToLogin) || !getUserName() || isOAuthLogin;
  }, [backToLogin, isOAuthLogin]);

  return (
    <Box css={loginFormBox}>
      <FieldGroup>
        {showNameField && (
          <Box>
            <Field>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    variant="standard"
                    placeholder={intl.get('请输入账号')}
                    fullWidth
                    inputRef={inputRef}
                    error={showErrorStyle}
                    {...field}
                  />
                )}
              />
            </Field>
          </Box>
        )}
        <Field>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <PasswordInput
                placeholder={intl.get('请输入密码')}
                isError={showErrorStyle}
                css={{
                  marginTop: showNameField ? '52px' : '0px',
                }}
                autoFocus={true}
                iconVariant="5"
                {...field}
              />
            )}
          />
        </Field>
      </FieldGroup>
    </Box>
  );
};

const LoginForm = (props: LoginFormPropsType) => {
  const { handleClickBackToLogin, firstLogin = true, isOAuthLogin } = props;
  const theme = useTheme();

  const router = useRouter();
  const [getRsa] = useGetRsaKeyMutation();
  const [getToken] = useGetTokenMutation();
  const [showFormError, setShowFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [readProtocol, setReadProtocol] = useState<string[]>([]);
  const [backToLogin, setBackToLogin] = useState(true);
  const [username, setUsernameInput] = useState('');
  const [password, setPasswordInput] = useState('');
  const [showErrorStyle, setShowErrorStyle] = useState(false);

  const defaultValues = {
    name: '',
    password: '',
  };
  const formRef = useRef<{
    submit: () => void;
  }>(null);

  const handleSubmit = async (inputValue: LoginInputType) => {
    if (firstLogin && readProtocol.length === 0) {
      return;
    }
    if (!!getUserName() && backToLogin) {
      if (!password) {
        return;
      }
    } else {
      if (!password || !username) {
        return;
      }
    }
    try {
      setLoading(true);
      const rsaResponse = await getRsa();
      const { uuid, publicKey } = rsaResponse?.data?.rsaKeyCreateOne || { uuid: '', publicKey: '' };
      const name = getUserName() && backToLogin ? getUserName() : inputValue.name;
      const tokenRes = await getToken({
        variables: {
          input: {
            data: {
              name,
              password: rsaEncrypt(inputValue.password, publicKey),
              rsaKeyUuid: uuid,
              eulaAccepted: true,
            },
          },
        },
      });
      setUserId(tokenRes?.data?.tokenLogin?.data?.userId);
      setEmailSettingAlert(false);
      const { redirect_url } = router.query;
      router.replace(
        redirect_url ? (redirect_url as string) : appRoute[AppRouteKey.ROUTE_HOME].address
      );
      ps.publish(LOGIN);
      setUserName(name);
    } catch (err: any) {
      if (err?.graphQLErrors[0]?.extensions?.statusCode === 401) {
        setShowFormError(true);
        setShowErrorStyle(true);
      } else {
        message.open('error', intl.get('登录异常'));
      }
      setLoading(false);
    }
  };

  const handleClick = useCallback(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }, []);

  const loginByEnter = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        handleClick();
      }
    },
    [handleClick]
  );
  const getUserInputChange = useCallback(
    (name: string, password: string) => {
      showErrorStyle ? setShowErrorStyle(false) : '';
      showFormError ? setShowFormError(false) : '';
      setUsernameInput(name);
      setPasswordInput(password);
    },
    [showErrorStyle, showFormError]
  );

  const tooltipTitle = useMemo(() => {
    if (loading) {
      return intl.get('正在登录，请稍等');
    } else if (!!getUserName() && backToLogin && !firstLogin) {
      if (!password) {
        return intl.get('请输入密码');
      }
    } else {
      if (!username && !password) {
        return intl.get('请输入账号和密码');
      } else if (!username && password) {
        return intl.get('请输入账号');
      } else if (username && !password) {
        return intl.get('请输入密码');
      } else if (firstLogin && readProtocol.length === 0) {
        return intl.get('请阅读并同意上方协议');
      }
    }
  }, [backToLogin, loading, username, password, firstLogin, readProtocol]);

  const CheckboxOptions: CheckboxGroupOption[] = [
    {
      label: intl.get('已阅读并同意'),
      value: 'read',
    },
  ];

  const handleClickProtocol = useCallback((v) => {
    setReadProtocol(v);
  }, []);

  const handleClickShowProtocol = useCallback(() => {
    dialog.widget({
      fullScreen: true,
      widget: 'Protocol',
      showTopNavi: false,
      widgetData: {
        noNeedConfirmation: false,
        action: () => {
          setReadProtocol(['read']);
        },
      },
    });
  }, []);

  const disabledLogin = useMemo(() => {
    return !!getUserName() && backToLogin && !firstLogin
      ? !password
      : !username || !password || (firstLogin && readProtocol.length === 0);
  }, [backToLogin, firstLogin, password, username, readProtocol.length]);
  return (
    <Box
      css={{
        position: 'relative',
        height: 'calc(100% - 152px)',
        paddingTop: showFormError ? '0px' : '80px',
      }}
      onKeyDown={loginByEnter}
    >
      {showFormError && (
        <Box className="login-error-tip" css={formErrorBox}>
          {intl.get('账号或密码错误')}
        </Box>
      )}
      <Form type="baseForm" ref={formRef} defaultValues={defaultValues} onSubmit={handleSubmit}>
        <LoginMessage
          getUserInputChange={getUserInputChange}
          backToLogin={backToLogin && !firstLogin}
          showErrorStyle={showErrorStyle}
          isOAuthLogin={isOAuthLogin}
        />
        {firstLogin && (
          <Box
            className="PLogin-checkboxContainer"
            css={{
              display: 'flex',
              padding: '40px 0 16px 0',
              '& .CharonCheckbox .CharonTypography': {
                color: themeCSSObj.paletteCommonWhite,
                opacity: 0.8,
              },
            }}
          >
            <Box data-e2e="PLogin-checkbox">
              <CheckboxGroup
                value={readProtocol}
                options={CheckboxOptions}
                onChange={(v) => handleClickProtocol(v)}
              />
            </Box>
            <Typography
              type="primary"
              className="PLogin-protocol"
              data-e2e="PLogin-protocolName"
              color="#AD8DFD"
              css={{
                marginLeft: theme.spacing(0.5),
                '&:hover': {
                  color: '#AD8DFD', //色板未提供，但需要使用
                },
                '&:active': {
                  color: '#C5ACFD', //色板未提供，但需要使用
                },
              }}
              onClick={() => handleClickShowProtocol()}
            >
              {intl.get('最终用户许可协议')}
            </Typography>
          </Box>
        )}
        <Button
          fullWidth
          loading={loading}
          data-e2e="submit"
          onClick={handleClick}
          tooltip={tooltipTitle}
          size="large"
          disabled={disabledLogin}
          css={{
            marginTop: firstLogin ? '0px' : '56px',
            marginBottom: `${!!getUserName() && backToLogin && !firstLogin ? '0px' : '56px'}`,
            '& .Mui-disabled': {
              opacity: '0.6 !important',
            },
          }}
        >
          <Box
            css={
              getLocalLanguage() === 'zhCN'
                ? { letterSpacing: '8px', marginLeft: '8px' }
                : { wordSpacing: '8px' }
            }
          >
            {intl.get('登录')}
          </Box>
        </Button>
        {!!getUserName() && backToLogin && !firstLogin && (
          <Typography
            size="s1"
            type="shade-t1"
            css={{
              opacity: '0.8',
              margin: '32px 0',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setBackToLogin(false);
              handleClickBackToLogin(true);
            }}
          >
            {intl.get('切换账号')}
          </Typography>
        )}
      </Form>
    </Box>
  );
};

export default LoginForm;
