import { css } from '@emotion/react';
import { buttonClasses } from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import { ButtonClasses } from '~/components/ui/Button/lib';
import themeCSSObj from '~/styles/theme.module.scss';

export const labelCSS = css({
  display: 'flex',
  alignItems: 'center',
  color: themeCSSObj.paletteTextSecondary,
  paddingBottom: '4px',
});

export const getBtnCSS = (expand: boolean) =>
  css({
    [`.${ButtonClasses['root']}`]: {
      padding: 0,
      color: themeCSSObj.paletteTextSecondary,
      '&:hover': {
        borderColor: 'transparent',
        color: themeCSSObj.paletteActionHover,
        background: themeCSSObj.paletteCommonWhite,
      },
      [`.${buttonClasses['endIcon']}`]: {
        marginLeft: 0,
        transform: `rotate(${expand ? 180 : 0}deg)`,
        transition: 'transform .2s',
      },
    },
  });

export const useValidatingIconCSS = function () {
  const theme = useTheme();
  return css({
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    background: themeCSSObj.paletteCommonWhite,
    zIndex: 1,
  });
};
