import React from 'react';
import { Box } from '@mui/material';
import lodash from 'lodash';

import Typography from '~/components/ui/Typography';

import { FormErrorsProps } from './type';
import { errorsMsgCSS } from './lib';

function isError(error: any) {
  return error !== undefined && error.message;
}

// 当errors 是一个对象时，取第一个错误
function getFirstError(errors: any) {
  if (errors) {
    for (const key in errors) {
      if (isError(errors[key])) return errors[key];
    }
  }
}

const FormErrors = (props: FormErrorsProps) => {
  const { errors = {}, name } = props;

  const error = lodash.get(errors, name);
  if (!isError(error)) {
    if (error) {
      // 处理errors是一个对象的情况
      const firstError = getFirstError(error);
      if (firstError)
        return (
          <Typography className="PFormField-FormErrors">
            <Box css={errorsMsgCSS}>{firstError.message}</Box>
          </Typography>
        );
    }
  }
  return (
    <Typography className="PFormField-FormErrors">
      <Box css={errorsMsgCSS}>{lodash.get(errors, name)?.message}</Box>
    </Typography>
  );
};

export default FormErrors;
