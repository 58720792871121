import Box from '@mui/material/Box';
import intl from 'react-intl-universal';

import themeCSSObj from '~/styles/theme.module.scss';

type Props = {
  color?: string;
  fontSize?: string;
  lineHeight?: string;
};

export default function Copyright({
  color,
  fontSize = themeCSSObj.typographySubtitle2FontSize,
  lineHeight = themeCSSObj.typographySubtitle2LineHeight,
}: Props) {
  return (
    <Box
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}>
      Copyright &copy; {intl.get('北京星辰天合科技股份有限公司')}
    </Box>
  );
}
