import React, { useCallback, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { Box } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';

import LoginForm from '~/components/forms/LoginForm';
import Copyright from '~/components/common/Copyright';
import { getColorAlpha } from '~/lib/color';
import themeCSSObj from '~/styles/theme.module.scss';
import loginLogoImg from '~/public/images/login-logo.png';
import { IMAGE_QUALITY } from '~/constants';
import { getUserName } from '~/lib/store';
import Typography from '~/components/blocks/Typography';
import { COMMA } from '~/lib/util';
import { useGetSettingsEulaQuery } from '~/__generated__/graphql/app/operations/setting.graphql';
import dialog from '~/lib/dialog';

import { loginContainer, logoBox, loginPage, loginPageHeader, loginPageFooter } from './lib';

function LoginPage() {
  const [greetingStatus, setGreetingStatus] = useState(false);
  const { data: settingEulaData, loading: settingEulaLoading } = useGetSettingsEulaQuery({
    fetchPolicy: 'network-only',
  });
  const updateGreetingStatus = useCallback((value: boolean) => {
    setGreetingStatus(value);
  }, []);
  const greeting = useMemo(() => {
    let hour = new Date().getHours();
    if (greetingStatus || !getUserName()) {
      return;
    } else if (hour >= 5 && hour < 8) {
      return intl.get('早上好');
    } else if (hour >= 8 && hour < 12) {
      return intl.get('上午好');
    } else if (hour >= 12 && hour < 13) {
      return intl.get('中午好');
    } else if (hour >= 13 && hour < 19) {
      return intl.get('下午好');
    } else if (hour >= 19 && hour < 24) {
      return intl.get('晚上好');
    } else {
      return intl.get('凌晨好');
    }
  }, [greetingStatus]);

  const handleClickShowProtocol = () => {
    dialog.widget({
      fullScreen: true,
      widget: 'Protocol',
      showTopNavi: false,
      widgetData: {
        noNeedConfirmation: true,
      },
    });
  };

  const firstLogin = useMemo(() => {
    return settingEulaData?.eulaSettingResp?.data?.eulaAccepted;
  }, [settingEulaData?.eulaSettingResp?.data?.eulaAccepted]);

  // isOAuthLogin
  const router = useRouter();
  const { redirect_url } = router.query;
  const isOAuthLogin = useMemo(() => {
    if (!redirect_url) return false;
    const redirect = Array.isArray(redirect_url) ? redirect_url[0] : redirect_url;
    return redirect.startsWith('/oauth');
  }, [redirect_url]);

  return (
    <Box css={loginContainer}>
      <Box css={logoBox}>
        <Image src={loginLogoImg} alt="xsky logo" width="205" height="24" quality={IMAGE_QUALITY} />
      </Box>
      {!settingEulaLoading && (
        <Box css={loginPage} data-e2e="Login-form">
          <Typography data-e2e="Login-title" size="s5" isEllipsis css={loginPageHeader}>
            {greeting && firstLogin && !isOAuthLogin
              ? `${greeting}${COMMA()}${getUserName()}`
              : intl.get('账号登录')}
          </Typography>
          <LoginForm
            handleClickBackToLogin={updateGreetingStatus}
            firstLogin={!firstLogin}
            isOAuthLogin={isOAuthLogin}
          />
        </Box>
      )}
      {!settingEulaLoading && (
        <Box css={loginPageFooter} data-e2e="Login-footer">
          <Copyright color={getColorAlpha(themeCSSObj.paletteCommonWhite, 0.8)} />
          {firstLogin && (
            <Box css={{ display: 'flex' }}>
              <Typography type="shade-t2">｜</Typography>
              <Typography
                size="s1"
                type="primary"
                underline
                css={{
                  opacity: 0.8,
                  color: themeCSSObj.paletteCommonWhite,
                  '&:hover': {
                    color: '#AD8DFD', //色板未提供，但需要使用
                  },
                  '&:active': {
                    color: '#C5ACFD', //色板未提供，但需要使用
                  },
                }}
                onClick={() => handleClickShowProtocol()}
              >
                {intl.get('最终用户许可协议')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default LoginPage;
