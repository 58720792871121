import React, { forwardRef, useState, useCallback, MutableRefObject } from 'react';
import { OutlinedInput, InputAdornment } from '@mui/material';
import intl from 'react-intl-universal';

import IconButton from '~/components/ui/IconButton';

import { getOutlinedInputCSS, adornmentCSS } from './lib';
import type { PasswordInputProps } from './type';

const _PasswordInput = (props: PasswordInputProps, ref: MutableRefObject<HTMLInputElement>) => {
  const { width = '100%', height = '34px', isError = false, iconVariant = '2', ...rest } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const outlinedInputCSS = getOutlinedInputCSS(width, height, isError);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <OutlinedInput
      error={isError}
      css={outlinedInputCSS}
      inputRef={ref}
      type={showPassword ? 'text' : 'password'}
      autoComplete="new-password" // 禁止浏览器自动填充密码
      endAdornment={
        <InputAdornment css={adornmentCSS} disableTypography position="end">
          <IconButton
            tooltip={showPassword ? intl.get('隐藏') : intl.get('显示')}
            variant={iconVariant}
            onClick={handleClickShowPassword}
            icon={showPassword ? 'EyeOpen16' : 'EyeClose16'}
          />
        </InputAdornment>
      }
      {...rest}
    />
  );
};

const PasswordInput = forwardRef(_PasswordInput);

export default PasswordInput;
