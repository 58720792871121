import React, { forwardRef, useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import intl from 'react-intl-universal';
import clsx from 'clsx';
import lodash from 'lodash';

import Typography from '~/components/ui/Typography';
import FormErrors from '~/components/common/FormErrors';
import Icon from '~/charon/Icon';
import Button from '~/components/ui/Button';
import themeCSSObj from '~/styles/theme.module.scss';
import Spinner from '~/components/ui/Spinner';

import { FieldProps } from './type';
import { labelCSS, getBtnCSS, useValidatingIconCSS } from './lib';

type FieldRef = React.MutableRefObject<{
  labelRef?: React.RefObject<HTMLElement | undefined>;
}>;

const Field = forwardRef((props: FieldProps, ref: FieldRef) => {
  const {
    children,
    label,
    required = false,
    errors = {},
    isValidating,
    name,
    titleRight,
    helpText,
    onAdvance,
    className,
  } = props;
  const ValidatingIconCSS = useValidatingIconCSS();

  const [expand, setExpand] = useState(false);

  const btnCSS = getBtnCSS(expand);

  const handleBtn = () => {
    typeof onAdvance === 'function' && onAdvance();

    setExpand(!expand);
  };

  return (
    <Box className={clsx('PFormField-container', className)}>
      <Box
        ref={ref?.current?.labelRef ?? undefined}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {label && (
          <Typography>
            <Box css={labelCSS}>
              {label}
              {required && (
                <Icon name="Asterisk16" viewSize={12} color={themeCSSObj.paletteErrorMain} />
              )}
            </Box>
          </Typography>
        )}
        <Box
          sx={{
            color: themeCSSObj.appOfflineMain,
            flex: 1,
          }}
        >
          {titleRight}
        </Box>
      </Box>
      <Box
        css={{
          lineHeight: 0,
          position: 'relative',
        }}
      >
        {children}
        {isValidating && <Spinner size="small" css={ValidatingIconCSS}></Spinner>}
      </Box>
      {onAdvance && (
        <Button variant="text" css={btnCSS} endIcon={<Icon name="Down12" />} onClick={handleBtn}>
          {intl.get('展开高级选项')}
        </Button>
      )}
      {!lodash.isEmpty(errors) && lodash.has(errors, name as string) && (
        <FormErrors errors={errors} name={name as string} />
      )}
      {helpText && (
        <FormHelperText
          sx={{
            display:
              !lodash.isEmpty(errors) && lodash.has(errors, name as string) ? 'none' : 'block',
            color: themeCSSObj.appFontColor3,
            fontSize: themeCSSObj.typographySubtitle1FontSize,
          }}
        >
          {helpText}
        </FormHelperText>
      )}
    </Box>
  );
});

const FieldGroup = forwardRef(({ children, className = '' }, ref) => {
  const theme = useTheme();
  return (
    <Box
      className={clsx('PFormFieldGroup-root', className)}
      css={{
        '.PFormField-container + .PFormField-container': {
          marginTop: theme.spacing(2),
        },
      }}
    >
      {children}
    </Box>
  );
});

export { FieldGroup };
export default Field;
