import { css } from '@emotion/react';

import themeCSSObj from '~/styles/theme.module.scss';
import loginBoxImg from '~/public/images/login-box.png';

export const loginContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const logoBox = css({
  position: 'absolute',
  top: '48px',
  left: '48px',
});

export const loginPage = css({
  width: '364px',
  padding: '0 32px',
  backgroundImage: `url(${loginBoxImg.src})`,
  borderRadius: '24px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'calc(100% - 1px) calc(100% - 1px)',
  backdropFilter: 'blur(20px)',
  boxShadow: '-15px -15px 40px rgba(0, 0, 0, 0.2)',
});

export const loginLanguage = css({
  position: 'absolute',
  top: '24px',
  right: '24px',
  cursor: 'pointer',
  userSelect: 'none',
  '&:hover': {
    color: themeCSSObj.appPrimaryColor,
  },
});

export const loginPageHeader = css({
  fontWeight: +themeCSSObj.typographyFontWeightMedium,
  marginTop: '56px',
  color: themeCSSObj.paletteCommonWhite,
});

export const loginPageFooter = css({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '32px',
  bottom: 0,
  left: 0,
  right: 0,
  fontSize: themeCSSObj.typographySubtitle1FontSize,
  textAlign: 'center',
});
