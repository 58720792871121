/* 5d46b83948093fcd9f9f9c80b042f0e669f8d334
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSettingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSettingQuery = (
  { __typename?: 'Query' }
  & { setting?: Types.Maybe<(
    { __typename?: 'Setting' }
    & { data: (
      { __typename?: 'SettingData' }
      & Pick<Types.SettingData, 'id' | 'adminNetwork' | 'clusterAddress' | 'clusterName' | 'computeUnderlayNetwork' | 'cpuAllocationRatio' | 'memoryAllocationRatio' | 'createdAt' | 'creationFinish' | 'deletionBegin' | 'deletionFinish' | 'etag' | 'inventory' | 'name' | 'sdsEndpoint' | 'storageGatewayNetwork' | 'storagePrivateNetwork' | 'storagePublicNetwork' | 'updatedAt' | 'sdsIdpUuid'>
    ), metadata: (
      { __typename?: 'ResourceMeta' }
      & Pick<Types.ResourceMeta, 'createdAt' | 'creationFinish'>
    ) }
  )> }
);

export type GetSettingsEulaQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSettingsEulaQuery = (
  { __typename?: 'Query' }
  & { eulaSettingResp?: Types.Maybe<(
    { __typename?: 'EulaSettingResp' }
    & { data?: Types.Maybe<(
      { __typename?: 'EulaSetting' }
      & Pick<Types.EulaSetting, 'eulaAccepted'>
    )> }
  )> }
);

export type UpdateSettingsMutationVariables = Types.Exact<{
  settingUpdateReqInput?: Types.Maybe<Types.SettingUpdateReqInput>;
}>;


export type UpdateSettingsMutation = (
  { __typename?: 'Mutation' }
  & { settingUpdateOne?: Types.Maybe<(
    { __typename?: 'Setting' }
    & { data: (
      { __typename?: 'SettingData' }
      & Pick<Types.SettingData, 'id' | 'adminNetwork' | 'clusterAddress' | 'clusterName' | 'computeUnderlayNetwork' | 'createdAt' | 'creationFinish' | 'deletionBegin' | 'deletionFinish' | 'etag' | 'inventory' | 'name' | 'sdsEndpoint' | 'storageGatewayNetwork' | 'storagePrivateNetwork' | 'storagePublicNetwork' | 'updatedAt'>
    ), metadata: (
      { __typename?: 'ResourceMeta' }
      & Pick<Types.ResourceMeta, 'createdAt' | 'creationFinish'>
    ) }
  )> }
);


export const GetSettingDocument = gql`
    query GetSetting {
  setting {
    data {
      id
      adminNetwork
      clusterAddress
      clusterName
      computeUnderlayNetwork
      cpuAllocationRatio
      memoryAllocationRatio
      createdAt
      creationFinish
      deletionBegin
      deletionFinish
      etag
      inventory
      name
      sdsEndpoint
      storageGatewayNetwork
      storagePrivateNetwork
      storagePublicNetwork
      updatedAt
      sdsIdpUuid
    }
    metadata {
      createdAt
      creationFinish
    }
  }
}
    `;

/**
 * __useGetSettingQuery__
 *
 * To run a query within a React component, call `useGetSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetSettingQuery, GetSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingQuery, GetSettingQueryVariables>(GetSettingDocument, options);
      }
export function useGetSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingQuery, GetSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingQuery, GetSettingQueryVariables>(GetSettingDocument, options);
        }
export type GetSettingQueryHookResult = ReturnType<typeof useGetSettingQuery>;
export type GetSettingLazyQueryHookResult = ReturnType<typeof useGetSettingLazyQuery>;
export type GetSettingQueryResult = Apollo.QueryResult<GetSettingQuery, GetSettingQueryVariables>;
export const GetSettingsEulaDocument = gql`
    query GetSettingsEula {
  eulaSettingResp {
    data {
      eulaAccepted
    }
  }
}
    `;

/**
 * __useGetSettingsEulaQuery__
 *
 * To run a query within a React component, call `useGetSettingsEulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsEulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsEulaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsEulaQuery(baseOptions?: Apollo.QueryHookOptions<GetSettingsEulaQuery, GetSettingsEulaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingsEulaQuery, GetSettingsEulaQueryVariables>(GetSettingsEulaDocument, options);
      }
export function useGetSettingsEulaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsEulaQuery, GetSettingsEulaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingsEulaQuery, GetSettingsEulaQueryVariables>(GetSettingsEulaDocument, options);
        }
export type GetSettingsEulaQueryHookResult = ReturnType<typeof useGetSettingsEulaQuery>;
export type GetSettingsEulaLazyQueryHookResult = ReturnType<typeof useGetSettingsEulaLazyQuery>;
export type GetSettingsEulaQueryResult = Apollo.QueryResult<GetSettingsEulaQuery, GetSettingsEulaQueryVariables>;
export const UpdateSettingsDocument = gql`
    mutation UpdateSettings($settingUpdateReqInput: SettingUpdateReqInput) {
  settingUpdateOne(settingUpdateReqInput: $settingUpdateReqInput) {
    data {
      id
      adminNetwork
      clusterAddress
      clusterName
      computeUnderlayNetwork
      createdAt
      creationFinish
      deletionBegin
      deletionFinish
      etag
      inventory
      name
      sdsEndpoint
      storageGatewayNetwork
      storagePrivateNetwork
      storagePublicNetwork
      updatedAt
    }
    metadata {
      createdAt
      creationFinish
    }
  }
}
    `;
export type UpdateSettingsMutationFn = Apollo.MutationFunction<UpdateSettingsMutation, UpdateSettingsMutationVariables>;

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      settingUpdateReqInput: // value for 'settingUpdateReqInput'
 *   },
 * });
 */
export function useUpdateSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UpdateSettingsDocument, options);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = Apollo.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>;