// Owner: Yuliang Liao
// Link: https://www.figma.com/file/yH2At4lyrzmEgK6cnNGRTG/SDS-%E8%AE%BE%E8%AE%A1%E7%B3%BB%E7%BB%9F?node-id=171%3A3153

import React, { forwardRef, useCallback, useState, ChangeEvent } from 'react';
import { OutlinedInput, InputAdornment, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';

import themeCSSObj from '~/styles/theme.module.scss';
import Button from '~/components/ui/Button';
import IconButton from '~/components/ui/IconButton';
import Tooltip from '~/components/ui/Tooltip';
import { useGetFitContentWidth } from '~/lib/util';

import { InputClasses } from './lib';
import type { InputEndAdornmentProps, InputProps } from './type';
import Typography from '../Typography';
import { ButtonClasses } from '../Button/lib';

function InputEndAdornment({
  allowClear,
  endAdornment,
  error,
  onClear,
  maxCount,
  value,
  actionLabel,
  action,
}: InputEndAdornmentProps) {
  const theme = useTheme();

  if (allowClear === false && endAdornment === undefined && maxCount === undefined) {
    return null;
  }

  return (
    <InputAdornment
      disableTypography
      position="end"
      className={InputClasses['endAdornment']}
      css={{
        '> * + *': {
          marginLeft: theme.spacing(1),
        },
      }}
    >
      {allowClear && (
        <IconButton
          css={{
            opacity: value ? 1 : 0,
            transition: `opacity ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
            [`& .${ButtonClasses.root}`]: {
              cursor: value ? 'pointer' : 'initial',
            },
          }}
          className={InputClasses['clear']}
          icon="Error16"
          viewSize={12}
          onClick={() => onClear && onClear()}
        />
      )}
      {maxCount && (
        <Box>
          <Typography className={InputClasses['count']} type={error ? 'error' : 't3'}>
            {value?.length ?? 0}/{maxCount}
          </Typography>
        </Box>
      )}
      {actionLabel && action && (
        <Button
          className={InputClasses['action']}
          onClick={action}
          variant="text"
          css={{
            [`& .${ButtonClasses['root']}`]: {
              minWidth: 'auto',
              padding: 0,
            },
            [`& .${ButtonClasses['root']}:hover`]: {
              background: 'transparent',
              borderColor: 'transparent',
            },
          }}
        >
          {actionLabel}
        </Button>
      )}
      {endAdornment}
    </InputAdornment>
  );
}

const _Input = (props: InputProps, ref: React.MutableRefObject<HTMLInputElement>) => {
  const {
    endAdornment,
    allowClear = false,
    onChange,
    value,
    className,
    error,
    tooltip = '',
    width,
    tooltipProps,
    maxCount,
    action,
    actionLabel,
    showTipWhenFocused,
    fitContent,
    fullWidth = true,
    fontSize,
    fontWeight,
    ...rest
  } = props;

  const [focused, setFocused] = useState(false);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.value, e);
    },
    [onChange]
  );

  const handleClear = useCallback(() => {
    onChange && onChange('');
  }, [onChange]);

  const contentWidth = useGetFitContentWidth({
    label: value,
    fontSize: fontSize ? parseInt(fontSize) : +themeCSSObj.typographyFontSize,
    fontWeight: fontWeight ?? +themeCSSObj.typographyFontWeightMedium,
  });

  return (
    <Tooltip open={showTipWhenFocused ? focused : undefined} title={tooltip} {...tooltipProps}>
      <OutlinedInput
        inputRef={ref}
        onChange={handleInputChange}
        value={value}
        error={error}
        inputProps={{
          className: InputClasses['input'],
          style: {
            minWidth: '16px',
            width: fitContent ? contentWidth : '100%',
            color: themeCSSObj.appFontColor1,
          },
        }}
        className={clsx(InputClasses['root'], className)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        endAdornment={
          <InputEndAdornment
            allowClear={allowClear}
            maxCount={maxCount}
            endAdornment={endAdornment}
            onClear={handleClear}
            error={error}
            value={value}
            action={action}
            actionLabel={actionLabel}
          ></InputEndAdornment>
        }
        style={{
          width: width,
        }}
        fullWidth={fullWidth}
        {...rest}
      />
    </Tooltip>
  );
};

const Input = forwardRef(_Input);

export default Input;
