// Owner: Jin Zheng
// Link: https://www.figma.com/file/yH2At4lyrzmEgK6cnNGRTG/SDS-%E8%AE%BE%E8%AE%A1%E7%B3%BB%E7%BB%9F?node-id=174%3A4817

import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Checkbox as MuiCheckbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';

import Typography from '~/components/ui/Typography';
import Tooltip from '~/components/ui/Tooltip';

import { CheckboxGroupProps, CheckboxWithLabelProps } from './type';

const CheckboxWithLabel = forwardRef((props: CheckboxWithLabelProps, ref) => {
  const { tooltip, label, disabled, className, tooltipProps, labelColor, ...rest } = props;
  const theme = useTheme();
  return (
    <Tooltip className="CharonCheckbox" title={tooltip || ''} {...tooltipProps}>
      <FormControlLabel
        className={clsx(className)}
        css={{
          marginLeft: 0,
          marginRight: 0,
          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        }}
        control={<MuiCheckbox disabled={disabled} {...rest} />}
        label={
          typeof label === 'string' ? (
            <Typography
              type="t1"
              disabled={disabled}
              css={{ marginLeft: theme.spacing(0.5) }}
              color={labelColor}
            >
              {label}
            </Typography>
          ) : (
            <Box css={{ display: 'flex', minWidth: 0, flex: 1 }}>{label}</Box>
          )
        }
      />
    </Tooltip>
  );
});

const CheckboxGroup = forwardRef((props: CheckboxGroupProps, ref) => {
  const { options, disabled: allDisabled, value, defaultValue, onChange, row = false } = props;
  const [innerValue, setInnerValue] = useState(value || defaultValue || []);

  useEffect(() => {
    if (value !== undefined) {
      setInnerValue(value);
    }
  }, [value]);

  const handleChange = (checkedValue: string) => {
    const newValue = [...innerValue];
    const index = innerValue.indexOf(checkedValue);
    if (index === -1) {
      newValue.push(checkedValue);
    } else {
      newValue.splice(index, 1);
    }

    if (!value) {
      setInnerValue(newValue);
    }

    onChange?.(
      newValue.sort((a, b) => {
        const indexA = options.findIndex((opt) => opt.value === a);
        const indexB = options.findIndex((opt) => opt.value === b);

        return indexA - indexB;
      })
    );
  };
  const theme = useTheme();

  return (
    <FormGroup
      ref={ref}
      row={row}
      css={{
        rowGap: theme.spacing(1),
        columnGap: theme.spacing(1),
      }}
    >
      {options.map((option) => {
        const disabled = allDisabled || option.disabled;
        return (
          <CheckboxWithLabel
            tooltipProps={{
              placement: 'right',
            }}
            key={option.value}
            tooltip={option.tooltip}
            disabled={disabled}
            checked={innerValue.includes(option.value)}
            onChange={() => handleChange(option.value)}
            label={option.label}
          />
        );
      })}
    </FormGroup>
  );
});

export { CheckboxGroup, CheckboxWithLabel };
